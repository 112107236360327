@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInVague {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.3; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes grow {
  0% {
    transform: scale(0.005); }
  100% {
    transform: scale(1); } }

@keyframes growLittle {
  0% {
    transform: scale(0.8); }
  100% {
    transform: scale(1); } }

@keyframes growMedium {
  0% {
    transform: scale(0.5); }
  100% {
    transform: scale(1); } }

@keyframes growDot {
  0% {
    color: transparent;
    transform: scale(11) translateY(-5rem) translateX(-2rem); }
  100% {
    color: currentColor;
    transform: scale(1); } }

@keyframes typewriter {
  from {
    width: 0; }
  to {
    width: 100%; } }

@keyframes comeFromLeft {
  0% {
    transform: translateX(-100vw);
    opacity: 0; }
  85% {
    transform: translateX(2rem);
    opacity: .3; }
  100% {
    transform: translateX(1);
    opacity: 1; } }

@keyframes moveUp {
  0% {
    transform: translateY(5rem);
    opacity: 0; }
  100% {
    transform: translateY(1);
    opacity: 1; } }

@keyframes backgroundPosition {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: 75% 75%; } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  font-family: sans-serif;
  overflow-x: hidden; }

a {
  text-decoration: none;
  color: white; }

.link:link,
.link:visited {
  text-decoration: none; }

.reveal {
  position: relative;
  transform: translateY(7rem);
  opacity: 0;
  transition: 1.2s all ease; }

.reveal.active {
  transform: translateY(0);
  opacity: 1; }

.heading-1 {
  font-family: 'Maven Pro', sans-serif;
  font-size: 3.6rem;
  letter-spacing: .6rem;
  text-transform: uppercase;
  color: #acacac;
  cursor: pointer; }
  .heading-1 a:link,
  .heading-1 a:visited {
    text-decoration: none;
    color: inherit; }
  @media only screen and (max-width: 400px) {
    .heading-1 {
      font-size: 3rem; } }
  @media only screen and (max-width: 350px) {
    .heading-1 {
      font-size: 2rem; } }

.heading-2 {
  font-size: 1.6vw;
  color: #6e6e6e;
  font-weight: 200;
  letter-spacing: .2rem;
  font-family: 'Special Elite', cursive;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .heading-2 {
      font-size: 3.2vw; } }
  @media only screen and (max-width: 37.5em) {
    .heading-2 {
      font-size: 4.8vw; } }
  @media only screen and (max-width: 400px) {
    .heading-2 {
      font-size: 4.2vw; } }

.heading-3 {
  background-color: black;
  color: white;
  display: inline-block;
  font-size: 2rem;
  font-weight: 400;
  padding: .6rem 1.6rem;
  line-height: 1;
  margin-bottom: 1rem;
  text-align: left;
  width: 100%; }
  @media only screen and (max-width: 37.5em) {
    .heading-3 {
      font-size: 2.8rem;
      line-height: 1.2; } }
  .heading-3-wrap {
    width: auto; }
  .heading-3--question {
    font-family: 'Special Elite', cursive;
    font-size: 2.4rem; }

.heading-4 {
  font-size: 1.2vw;
  font-weight: 300;
  color: #b0b0b0;
  letter-spacing: 1px;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .heading-4 {
      font-size: 1.8vw; } }
  @media only screen and (max-width: 37.5em) {
    .heading-4 {
      font-size: 2.5vw; } }
  @media only screen and (max-width: 31.25em) {
    .heading-4 {
      font-size: 3.4vw; } }

.fadeIn-class {
  animation: fadeIn 1s; }

.fadeOut-class {
  animation: fadeOut 1s; }

.grow-class {
  animation: grow 2s 4s; }

.margin-bottom-realy-small {
  margin-bottom: 1rem; }

#blocker {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5; }

.back-btn {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: #c6c6c6;
  font-size: 3rem;
  color: #FFF;
  cursor: pointer;
  transition: all .3s; }
  .back-btn:hover {
    background-color: #efefef; }
  .back-btn:hover div {
    border-right: 1.8rem solid #dbdbdb; }
    @media only screen and (max-width: 31.25em) {
      .back-btn:hover div {
        border-right: 1.5rem solid white; } }
  @media only screen and (max-width: 31.25em) {
    .back-btn {
      height: 3rem;
      width: 3rem;
      font-size: 2.4rem; } }

.back-sign {
  position: absolute;
  top: 22%;
  left: 20%;
  width: 0;
  height: 0;
  border-top: 1.1rem solid transparent;
  border-bottom: 1.1rem solid transparent;
  border-right: 1.8rem solid white; }
  @media only screen and (max-width: 31.25em) {
    .back-sign {
      top: 18%;
      left: 18%;
      border-right: 1.5rem solid white;
      border-top: 1rem solid transparent;
      border-bottom: 1rem solid transparent; } }

.go-top {
  font-size: 2.2rem;
  cursor: pointer; }

.youtube {
  margin: 2rem 0;
  height: 25.3vw;
  width: 45vw; }
  @media only screen and (max-width: 56.25em) {
    .youtube {
      width: 65vw;
      height: 36.56vw; } }
  @media only screen and (max-width: 37.5em) {
    .youtube {
      width: 80vw;
      height: 45vw; } }
  @media only screen and (max-width: 31.25em) {
    .youtube {
      width: 90vw;
      height: 50.63vw; } }

.footer {
  background-color: black;
  color: grey;
  height: 16vh;
  text-align: center;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding-top: 3rem;
  padding-left: 13vw; }
  @media only screen and (max-width: 56.25em) {
    .footer {
      padding-left: 7vw; } }
  .footer__social {
    display: flex;
    margin-right: 5vw; }
    .footer__social-icon {
      width: 2.2rem;
      height: 2.2rem;
      margin-top: -.5rem;
      margin-right: 1.2rem;
      transition: all .3s; }
      .footer__social-icon--3 {
        margin-right: 0; }
      .footer__social-icon:hover {
        transform: translateY(-2px) scale(1.2); }

.header {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .header__img {
    width: 100vw; }
  @media only screen and (max-width: 31.25em) {
    .header {
      display: none; } }

.layout {
  width: 100vw;
  height: 100%;
  animation: fadeIn 2s;
  animation-fill-mode: backwards;
  position: relative; }

.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3; }
  @media only screen and (max-width: 31.25em) {
    .navigation {
      background-color: white;
      align-items: flex-start; } }
  .navigation__title {
    padding: 1.2rem 2rem;
    font-size: 2.5rem;
    background-color: white;
    margin-top: -3rem; }
    @media only screen and (max-width: 31.25em) {
      .navigation__title {
        font-size: 2.2rem;
        margin-top: 1rem;
        margin-left: 1.5vw;
        z-index: 3;
        padding: .5rem 2rem 1.5rem;
        background-color: transparent; } }
  .navigation__list {
    width: 100%;
    overflow: hidden;
    padding: 1.5rem 0 2.5rem 2rem;
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    transition: all .7s ease; }
    @media only screen and (max-width: 37.5em) {
      .navigation__list {
        padding: 1.5rem 0 2.5rem; } }
    @media only screen and (max-width: 31.25em) {
      .navigation__list {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: white;
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        z-index: 3;
        padding-top: 7vh; } }
  .navigation__item:not(:last-child) {
    margin-right: 4rem; }
  @media only screen and (max-width: 31.25em) {
    .navigation__item {
      margin-right: 0;
      padding: 1.5rem 3rem;
      padding-left: 15vw;
      width: 100%;
      text-align: left; } }
  .navigation__link:link, .navigation__link:visited {
    text-decoration: none;
    font-size: 1.6rem;
    padding: .5rem .3rem;
    color: black;
    border-top: 4px solid transparent;
    transition: all .4s; }
    @media only screen and (max-width: 31.25em) {
      .navigation__link:link, .navigation__link:visited {
        font-size: 2.5rem;
        color: #c1c1c1; } }
  .navigation__link:hover {
    border-top: 4px solid currentColor;
    color: #b083bf; }
  .navigation__link-active:link, .navigation__link-active:visited {
    text-decoration: none;
    font-size: 1.6rem;
    padding: .5rem .3rem;
    color: black;
    border-top: 4px solid #b083bf; }
    @media only screen and (max-width: 31.25em) {
      .navigation__link-active:link, .navigation__link-active:visited {
        border-top: 4px solid transparent;
        font-size: 2.5rem;
        color: #c1c1c1; } }
  .navigation__social-media {
    width: 1.6rem;
    margin-right: .8rem;
    cursor: pointer;
    transition: all .3s; }
    .navigation__social-media--3 {
      width: 1.4rem; }
    .navigation__social-media--mobile {
      display: none; }
      @media only screen and (max-width: 31.25em) {
        .navigation__social-media--mobile {
          display: inline-block;
          margin-top: 2rem;
          margin-right: 4vw;
          width: 1.5rem; }
          .navigation__social-media--mobile--3 {
            margin-right: 0; } }
    .navigation__social-media:hover {
      transform: translateY(-2px) scale(1.2); }
    @media only screen and (max-width: 31.25em) {
      .navigation__social-media {
        display: none; } }
  .navigation__checkbox {
    display: none; }
  .navigation__button {
    height: 4rem;
    width: 4rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
    text-align: center;
    cursor: pointer;
    z-index: 4;
    display: none; }
    @media only screen and (max-width: 31.25em) {
      .navigation__button {
        display: block; } }
  .navigation__checkbox:checked ~ .navigation__list {
    transform: translateX(100%); }
  .navigation__icon {
    position: relative;
    margin-top: .6rem; }
    .navigation__icon, .navigation__icon::before, .navigation__icon::after {
      width: 2rem;
      height: 2px;
      background-color: #7c7c7c;
      display: inline-block; }
    .navigation__icon::before, .navigation__icon::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .4s; }
    .navigation__icon::before {
      top: -5px; }
    .navigation__icon::after {
      top: 5px; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    transform: rotate(135deg); }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-135deg); }

.about {
  position: relative;
  font-family: sans-serif;
  margin-left: 12%;
  margin-bottom: 5rem; }
  .about__banner {
    position: relative;
    background-image: url("/public/img/about/top-banner-about.jpg");
    background-size: cover;
    background-position: top right;
    height: 40vh;
    animation: moveUp 2s 1s;
    animation-fill-mode: backwards; }
    @media only screen and (max-width: 37.5em) {
      .about__banner {
        background-image: url("/public/img/about/mobile-banner-about.jpg");
        background-position: top;
        width: 100%; } }
    @media only screen and (max-width: 31.25em) {
      .about__banner {
        position: relative;
        background-position: top left 40%;
        height: 35vh; } }
  .about__banner-title {
    position: absolute;
    bottom: 10%;
    right: 25%;
    font-weight: 300;
    color: white;
    font-size: 6rem;
    background-color: #b083bf;
    padding: 0 2rem 1rem;
    line-height: .8; }
    @media only screen and (max-width: 37.5em) {
      .about__banner-title {
        bottom: 0;
        left: 45%;
        right: auto;
        transform: translateY(50%); } }
  .about__top-bubbles {
    width: 13vw;
    position: absolute;
    right: 6vw;
    top: -15vh;
    z-index: -1;
    opacity: .6;
    animation: moveUp 1s 2s;
    animation-fill-mode: backwards; }
    @media only screen and (max-width: 31.25em) {
      .about__top-bubbles {
        width: 25vw;
        top: -12vh;
        right: 0; } }
  @media only screen and (max-width: 56.25em) {
    .about {
      margin: 18vh 5vw 10vw; } }
  @media only screen and (max-width: 37.5em) {
    .about {
      margin-top: 15vh; } }
  .about__top-section {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-template-rows: repeat(2, min-content);
    margin-top: 10rem;
    position: relative; }
  .about__intro {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    animation: moveUp 1.5s 2s;
    animation-fill-mode: backwards; }
    @media only screen and (max-width: 31.25em) {
      .about__intro {
        grid-column: 1 / 3; } }
  .about__quote {
    font-family: 'Special Elite', cursive;
    font-size: 2.5rem;
    color: #c4c4c4;
    margin-top: 1.5rem;
    margin-bottom: 6rem;
    margin-left: .5rem;
    line-height: 1.1; }
    @media only screen and (max-width: 31.25em) {
      .about__quote {
        margin-bottom: 3rem; } }
  .about__strong {
    font-size: 2rem;
    background-color: #b083bf;
    width: max-content;
    padding: .2rem 1rem;
    color: white;
    text-align: left;
    margin: 3rem .5rem 1.5rem 0; }
  .about__text {
    font-size: 1.8rem;
    line-height: 1.3;
    color: #5a5a5a;
    margin-bottom: 2rem;
    text-align: justify;
    padding-bottom: 2rem; }
    .about__text--black {
      color: black; }
    .about__text--special {
      width: 37rem; }
    @media only screen and (max-width: 37.5em) {
      .about__text {
        font-size: 2rem; } }
  .about__intro-images {
    position: relative;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    animation: moveUp 1.5s 3s;
    animation-fill-mode: backwards; }
    @media only screen and (max-width: 31.25em) {
      .about__intro-images {
        display: none; } }
    .about__intro-images--1 {
      position: absolute;
      top: 0;
      right: 20%;
      width: 8vw; }
      @media only screen and (max-width: 37.5em) {
        .about__intro-images--1 {
          width: 30vw;
          right: 60%;
          width: 30%; } }
      @media only screen and (max-width: 31.25em) {
        .about__intro-images--1 {
          top: -85%;
          width: 2.5vw;
          right: 10%;
          display: none; } }
    .about__intro-images--2 {
      position: absolute;
      bottom: -10%;
      left: 5%;
      width: 60%; }
      @media only screen and (max-width: 75em) {
        .about__intro-images--2 {
          width: 60%; } }
      @media only screen and (max-width: 700px) {
        .about__intro-images--2 {
          width: 70%; } }
      @media only screen and (max-width: 37.5em) {
        .about__intro-images--2 {
          width: 90%;
          bottom: 0;
          right: 0;
          left: auto; } }
      @media only screen and (max-width: 31.25em) {
        .about__intro-images--2 {
          position: static;
          width: 15rem; } }
  .about__intro-mobile-image {
    display: none; }
    @media only screen and (max-width: 31.25em) {
      .about__intro-mobile-image {
        display: inline-block;
        position: relative;
        bottom: -1rem;
        width: 30vw;
        float: right;
        shape-outside: url("/public/img/about/intro-profile.png");
        shape-margin: 1rem;
        margin-top: 6rem;
        margin-left: 2.2rem;
        border-radius: 50%;
        left: 1rem; } }
  .about__declaration {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    position: relative; }
    @media only screen and (max-width: 56.25em) {
      .about__declaration {
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        margin-top: 3rem; } }
    @media only screen and (max-width: 31.25em) {
      .about__declaration {
        margin-top: 5rem; } }
  .about__declaration-image {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    @media only screen and (max-width: 56.25em) {
      .about__declaration-image {
        display: none; } }
  .about__declaration-circle--1 {
    display: none; }
    @media only screen and (max-width: 56.25em) {
      .about__declaration-circle--1 {
        display: inline; } }
  .about__declaration-circle--2 {
    width: 90%; }
    @media only screen and (max-width: 75em) {
      .about__declaration-circle--2 {
        width: 97%; } }
  @media only screen and (max-width: 56.25em) {
    .about__declaration-circle {
      position: relative;
      bottom: auto;
      float: right;
      shape-outside: url("/public/img/about/about-full-circle.png");
      width: 40vw;
      right: -5vw; } }
  @media only screen and (max-width: 37.5em) {
    .about__declaration-circle {
      width: 50vw;
      right: -5vw;
      margin-top: 5rem; } }
  @media only screen and (max-width: 31.25em) {
    .about__declaration-circle {
      width: 60vw;
      margin-top: 0; } }
  .about__signature {
    display: flex;
    justify-content: flex-end;
    margin-top: -1rem; }
  .about__signature-image {
    width: 20rem; }
  .about__interview {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 5rem; }
    @media only screen and (max-width: 37.5em) {
      .about__interview {
        grid-template-columns: 1fr; } }
    .about__interview-header {
      display: flex;
      margin-top: 5rem; }
      @media only screen and (max-width: 37.5em) {
        .about__interview-header {
          width: 90vw;
          height: 90vw;
          background-image: url("/public/img/about/interview-image.jpg");
          background-size: cover; } }
    .about__interview-title {
      color: #b083bf;
      font-size: 2.8vw;
      font-weight: 200;
      letter-spacing: 1rem;
      text-align: center;
      margin-bottom: 4rem;
      display: block;
      width: 100%; }
      @media only screen and (max-width: 37.5em) {
        .about__interview-title {
          font-size: 5vw;
          letter-spacing: .8rem; } }
    .about__interview-image {
      width: 50%; }
      @media only screen and (max-width: 37.5em) {
        .about__interview-image {
          display: none; } }
    .about__interview-caption {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; }
      @media only screen and (max-width: 37.5em) {
        .about__interview-caption {
          width: 100%; } }
    .about__interview-heading {
      font-size: 2.6rem;
      letter-spacing: 1rem;
      font-weight: 300; }
      @media only screen and (max-width: 700px) {
        .about__interview-heading {
          font-size: 2.2rem;
          letter-spacing: .8rem; } }
      @media only screen and (max-width: 37.5em) {
        .about__interview-heading {
          font-size: 4vw;
          letter-spacing: 1.5vw;
          position: absolute;
          top: 30%;
          left: 35%; } }
    .about__interview-bubbles {
      position: absolute;
      top: 0;
      left: 42%;
      width: 12rem; }
      @media only screen and (max-width: 37.5em) {
        .about__interview-bubbles {
          display: none; } }
    .about__interview-text {
      position: relative; }
    .about__interview-bottom-image {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      width: 100%;
      position: absolute;
      top: 20%;
      right: -100%; }
      @media only screen and (max-width: 37.5em) {
        .about__interview-bottom-image {
          position: static;
          top: auto;
          right: auto;
          height: 100vw;
          grid-column: 1 / 2; } }
  .about__back-btn {
    position: absolute; }
    @media only screen and (max-width: 37.5em) {
      .about__back-btn {
        left: 1rem;
        bottom: -2rem; } }
  .about__go-top {
    position: absolute;
    bottom: -3rem;
    right: -8rem;
    z-index: 3; }
    @media only screen and (max-width: 37.5em) {
      .about__go-top {
        right: 2rem;
        bottom: -3rem; } }

.contact {
  display: grid;
  grid-template-columns: repeat(2, min-content); }
  @media only screen and (max-width: 31.25em) {
    .contact {
      grid-template-columns: min-content; } }
  .contact__image {
    width: 52vw; }
  .contact__form-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35vw;
    animation: moveUp 2s 1s;
    animation-fill-mode: backwards; }
    @media only screen and (max-width: 31.25em) {
      .contact__form-area {
        width: 100vw; } }
  .contact__title {
    display: inline;
    font-size: 4.5rem;
    font-weight: 300;
    background-color: #b083bf;
    line-height: .8;
    padding: .2rem 1rem .4rem;
    text-align: center; }

.form {
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
  margin-bottom: 3rem; }
  .form__label {
    font-size: 1.3rem;
    margin-left: .2rem;
    margin-bottom: .5rem; }
  .form__input {
    margin-bottom: 2rem;
    border-radius: 0%;
    border: 1.5px solid black;
    padding: .3rem;
    width: 40rem; }
    @media only screen and (max-width: 37.5em) {
      .form__input {
        width: 30rem; } }
    @media only screen and (max-width: 31.25em) {
      .form__input {
        width: 65vw; } }
  .form__textarea {
    margin-bottom: 1rem;
    border-radius: 0%;
    border: 1.5px solid black;
    padding: .4rem;
    resize: none; }
  .form__submit {
    border-radius: 50%;
    border: .9vw solid black;
    font-size: 2.2vw;
    font-weight: 600;
    background-color: #b083bf;
    margin-left: auto;
    cursor: pointer;
    transition: all .5s;
    width: 8vw;
    height: 8vw; }
    .form__submit:hover {
      color: white;
      border: 0.8rem solid #b083bf;
      background-color: black;
      transform: scale(1.2); }
    @media only screen and (max-width: 75em) {
      .form__submit {
        font-size: 2.5vw;
        width: 9vw;
        height: 9vw;
        border: 1.1vw solid black; } }
    @media only screen and (max-width: 56.25em) {
      .form__submit {
        font-size: 3vw;
        width: 12vw;
        height: 12vw;
        border: 1.5vw solid black; } }
    @media only screen and (max-width: 37.5em) {
      .form__submit {
        font-size: 4vw;
        width: 18vw;
        height: 18vw;
        border: 1.8vw solid black; } }

.vl {
  height: 11rem;
  border-left: 1.5px solid black; }
  @media only screen and (max-width: 37.5em) {
    .vl {
      height: 9rem; } }
  @media only screen and (max-width: 400px) {
    .vl {
      height: 6.5rem;
      border-left: 1.5px dashed #989898;
      z-index: -1; } }

.gallery {
  display: grid;
  padding: 1.5vw 20vw 5vw;
  gap: 3.2rem;
  position: relative; }
  .gallery--grid-2 {
    grid-template-columns: repeat(2, 1fr); }
    @media only screen and (max-width: 31.25em) {
      .gallery--grid-2 {
        grid-template-columns: 1fr; } }
  .gallery--grid-3 {
    grid-template-columns: repeat(3, 1fr); }
    @media only screen and (max-width: 31.25em) {
      .gallery--grid-3 {
        grid-template-columns: 1fr; } }
  @media only screen and (min-width: 1600px) {
    .gallery {
      padding: 1.5vw 25vw 3rem; } }
  @media only screen and (max-width: 75em) {
    .gallery {
      padding: 0 16rem 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .gallery {
      padding: 0 12rem 3rem;
      gap: 2.2rem; } }
  @media only screen and (max-width: 37.5em) {
    .gallery {
      padding: 0 5rem 3rem;
      gap: 1.5rem; } }
  @media only screen and (max-width: 31.25em) {
    .gallery {
      padding: 0 3rem 3rem;
      gap: 2.5rem; } }
  .gallery__back-btn {
    position: absolute;
    left: 15vw; }
    @media only screen and (max-width: 75em) {
      .gallery__back-btn {
        left: 12rem; } }
    @media only screen and (max-width: 56.25em) {
      .gallery__back-btn {
        left: 6rem; } }
    @media only screen and (max-width: 31.25em) {
      .gallery__back-btn {
        left: 2.5rem; } }
    @media only screen and (min-width: 1600px) {
      .gallery__back-btn {
        left: 20vw; } }
  .gallery__header {
    grid-column: 1 / -1;
    padding: 1.5rem 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0rem;
    z-index: 2; }
    @media only screen and (max-width: 31.25em) {
      .gallery__header {
        padding: 2rem 0 1rem 3rem; } }
  .gallery__item {
    cursor: pointer;
    display: block;
    transition: all .5s;
    position: relative;
    perspective: 140rem; }
  .gallery__item:hover {
    transform: translateY(-3px) scale(1.05); }
    @media only screen and (max-width: 37.5em) {
      .gallery__item:hover {
        transform: translateY(1) scale(1); } }
  .gallery__item::before {
    content: "";
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all .5s; }
  .gallery__item:hover::before {
    background-color: rgba(255, 255, 255, 0.6); }
    @media only screen and (max-width: 37.5em) {
      .gallery__item:hover::before {
        background-color: transparent; } }
  .gallery__image {
    width: 100%;
    animation: growLittle 2s ease;
    animation-fill-mode: backwards;
    pointer-events: none; }
  .gallery__lightbox {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.92);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: 3;
    transition: all .7s ease-in-out; }
    .gallery__lightbox img {
      display: block;
      height: 90%;
      line-height: 0;
      pointer-events: none; }
      @media only screen and (max-width: 37.5em) {
        .gallery__lightbox img {
          height: auto;
          width: 90%; } }
      @media only screen and (min-width: 1400px) {
        .gallery__lightbox img {
          height: 80%; } }
      @media only screen and (min-width: 1800px) {
        .gallery__lightbox img {
          height: 75%; } }
    .gallery__lightbox--open {
      visibility: visible;
      opacity: 1; }
  .gallery__x-mark {
    position: absolute;
    display: block;
    height: 4rem;
    width: 4rem;
    top: 5rem;
    right: 10rem;
    cursor: pointer; }
    @media only screen and (max-width: 700px) {
      .gallery__x-mark {
        right: 5rem;
        top: 3rem; } }
    @media only screen and (max-width: 550px) {
      .gallery__x-mark {
        right: 3rem;
        top: 2rem; } }
    .gallery__x-mark::before, .gallery__x-mark::after {
      width: 3rem;
      height: 2px;
      background-color: #ebebeb;
      display: inline-block;
      position: absolute;
      transition: all .4s; }
    .gallery__x-mark::before {
      content: "";
      top: 1.5rem;
      right: 0;
      transform: rotate(45deg); }
    .gallery__x-mark::after {
      content: "";
      top: 1.5rem;
      right: 0;
      transform: rotate(135deg); }
    .gallery__x-mark:hover::before, .gallery__x-mark:hover::after {
      transform: rotate(180deg);
      background-color: white; }
  .gallery__arrow-left {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    cursor: pointer;
    left: 5rem; }
    .gallery__arrow-left::before, .gallery__arrow-left::after {
      content: "";
      display: block;
      height: 2px;
      width: 2rem;
      background-color: lightgrey;
      z-index: 1;
      position: absolute;
      top: 0;
      margin-top: 2rem;
      margin-left: 1rem; }
    .gallery__arrow-left:hover::before, .gallery__arrow-left:hover::after {
      background-color: white; }
    @media only screen and (max-width: 37.5em) {
      .gallery__arrow-left {
        top: 100%; } }
    @media only screen and (max-width: 550px) {
      .gallery__arrow-left {
        left: 2rem; } }
    .gallery__arrow-left::before {
      top: 1px;
      transform: rotate(-45deg);
      transform-origin: left; }
    .gallery__arrow-left::after {
      transform: rotate(45deg);
      transform-origin: left; }
  .gallery__arrow-right {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    cursor: pointer;
    right: 5rem; }
    .gallery__arrow-right::before, .gallery__arrow-right::after {
      content: "";
      display: block;
      height: 2px;
      width: 2rem;
      background-color: lightgrey;
      z-index: 1;
      position: absolute;
      top: 0;
      margin-top: 2rem;
      margin-left: 1rem; }
    .gallery__arrow-right:hover::before, .gallery__arrow-right:hover::after {
      background-color: white; }
    @media only screen and (max-width: 37.5em) {
      .gallery__arrow-right {
        top: 100%; } }
    @media only screen and (max-width: 550px) {
      .gallery__arrow-right {
        right: 2rem; } }
    .gallery__arrow-right::before {
      transform: rotate(-45deg);
      transform-origin: right; }
    .gallery__arrow-right::after {
      top: 1px;
      transform: rotate(45deg);
      transform-origin: right; }

.reveal-image {
  transform: scale(0.7) translateY(3rem);
  opacity: 0;
  transition: 1.3s all ease; }

.reveal-image.active {
  transform: scale(1) translateY(0);
  opacity: 1; }

.themes {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  padding: 5rem;
  justify-content: center;
  gap: 5rem; }
  @media only screen and (max-width: 37.5em) {
    .themes {
      grid-template-columns: min-content;
      gap: 1rem;
      margin-top: 3rem; } }
  @media only screen and (max-width: 31.25em) {
    .themes {
      padding: 0 5rem 6rem; } }
  .themes__figure {
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
    .themes__figure--1 {
      flex-direction: row-reverse; }
      @media only screen and (max-width: 37.5em) {
        .themes__figure--1 {
          flex-direction: row; } }
    @media only screen and (max-width: 31.25em) {
      .themes__figure {
        transform: translateY(-50%); } }
  .themes__image {
    display: block;
    height: 20vw;
    width: 20vw;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    border-bottom-left-radius: 300px;
    border-bottom-right-radius: 300px;
    -webkit-border-radius: 300px;
    -moz-border-radius: 300px;
    margin-bottom: 2rem;
    border: none;
    cursor: pointer;
    animation: grow 1.5s ease 3s;
    animation-fill-mode: backwards; }
    @media only screen and (max-width: 37.5em) {
      .themes__image {
        width: 40vw;
        height: 40vw; } }
    .themes__image:hover {
      background-color: #c1c1c1;
      outline: 2.5rem solid #c1c1c1; }
    .themes__image:hover + figcaption {
      color: #c1c1c1; }
    @media only screen and (max-width: 31.25em) {
      .themes__image {
        animation: grow 1.5s ease 3s;
        animation-fill-mode: backwards;
        background-color: #f4f4f4;
        outline: 1.5rem solid #f4f4f4;
        margin-bottom: 5rem; } }
    @media only screen and (max-width: 31.25em) {
      .themes__image--1 {
        background-color: #e4e4e4;
        outline: 1.5rem solid #e4e4e4; } }
  .themes__caption {
    text-align: center;
    font-size: 2rem;
    margin: 1rem;
    color: #5e5e5e;
    cursor: pointer;
    width: 20rem;
    animation: moveUp 1.2s 1.5s;
    animation-fill-mode: backwards; }
    @media only screen and (max-width: 75em) {
      .themes__caption {
        font-size: 2.2rem;
        width: 15rem; } }
    @media only screen and (max-width: 37.5em) {
      .themes__caption {
        grid-template-columns: min-content;
        font-size: 2.2rem;
        width: 20rem;
        color: #9b9b9b;
        animation: moveUp 1.2s 2s;
        animation-fill-mode: backwards; } }
    @media only screen and (max-width: 31.25em) {
      .themes__caption {
        font-size: 2rem;
        margin: .6rem;
        width: 15rem; } }
  .themes__mobile-banner {
    display: none; }
    @media only screen and (max-width: 31.25em) {
      .themes__mobile-banner {
        display: block;
        width: 100vw;
        z-index: -1;
        opacity: 0.3;
        animation: fadeInVague 1.2s 1s;
        animation-fill-mode: backwards; } }

.introduction {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  overflow-x: hidden; }
  @media only screen and (max-width: 37.5em) {
    .introduction {
      display: block; } }
  .introduction__top-banner {
    display: none; }
    @media only screen and (max-width: 31.25em) {
      .introduction__top-banner {
        display: block;
        width: 100vw; } }
  .introduction__top-image {
    grid-row: 1 / 2;
    grid-column: 1 / 2; }
    @media only screen and (max-width: 31.25em) {
      .introduction__top-image {
        display: none; } }
    .introduction__top-image--1 {
      width: 92%; }
      @media only screen and (max-width: 37.5em) {
        .introduction__top-image--1 {
          width: 47%; } }
  .introduction__btn {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    width: 12vw;
    height: 12vw;
    border-radius: 50%;
    border: none;
    color: white;
    background-color: black;
    cursor: pointer;
    font-size: 5.3vw;
    font-weight: 600;
    text-decoration: none;
    position: absolute;
    top: -2rem;
    right: -5%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .4s;
    animation: grow 1.5s 18s;
    animation-fill-mode: backwards; }
    @media only screen and (max-width: 37.5em) {
      .introduction__btn {
        position: relative;
        top: -4rem;
        right: auto;
        left: 5%;
        width: 25vw;
        height: 25vw;
        font-size: 11vw; } }
    .introduction__btn--2 {
      display: none;
      font-size: .2rem;
      font-weight: 300;
      color: white; }
    .introduction__btn:hover {
      font-size: 1.9rem;
      transform: scale(1.2);
      color: white;
      line-height: 1.4; }
    .introduction__btn:hover .introduction__btn--1 {
      display: none; }
    .introduction__btn:hover .introduction__btn--2 {
      display: inline;
      font-size: 1.8rem;
      font-weight: 600;
      animation: grow .4s;
      animation-fill-mode: backwards; }
  .introduction__hr {
    height: 1px;
    background-color: white;
    border: none;
    margin: 1.5rem 0; }
  .introduction__text {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    display: block;
    height: 100%;
    background-color: white;
    color: #c2c2c2;
    line-height: 1.3;
    padding: 12% 4.5rem 6rem;
    text-align: right;
    font-size: 4vw;
    white-space: nowrap;
    font-family: 'Special Elite', cursive; }
    @media only screen and (max-width: 37.5em) {
      .introduction__text {
        font-size: 7vw;
        grid-row: 2 / 3;
        line-height: 1.2;
        padding: 2rem 3rem 0; } }
    @media only screen and (min-width: 1800px) {
      .introduction__text {
        font-size: 3.2vw;
        margin-right: 4vw; } }
    .introduction__text .introduction__text--1, .introduction__text .introduction__text--2, .introduction__text .introduction__text--3, .introduction__text .introduction__text--4, .introduction__text .introduction__text--5, .introduction__text .introduction__text--6, .introduction__text .introduction__text--7, .introduction__text .introduction__text--8 {
      display: block;
      overflow: hidden;
      animation-fill-mode: backwards; }
    .introduction__text--1 {
      animation: typewriter 2s steps(25, end) 2s; }
    .introduction__text--2 {
      animation: typewriter 2s steps(25, end) 4s; }
    .introduction__text--3 {
      animation: typewriter 2s steps(25, end) 6s; }
    .introduction__text--4 {
      animation: typewriter 2s steps(25, end) 8s; }
    .introduction__text--5 {
      animation: typewriter 2s steps(25, end) 10s; }
    .introduction__text--6 {
      animation: typewriter 2s steps(25, end) 12s; }
    .introduction__text--7 {
      animation: typewriter 2s steps(25, end) 14s; }
    .introduction__text--8 {
      animation: typewriter 2s steps(25, end) 16s;
      margin-right: 2rem; }
    .introduction__text--dot {
      display: inline-block;
      animation: growDot 1.2s cubic-bezier(1, 0, 0.9, 0.4) 16.5s;
      animation-fill-mode: backwards;
      display: flex;
      align-items: flex-end;
      position: absolute;
      right: 0;
      bottom: 0; }
  .introduction__bottom-image {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    display: block;
    width: 75%;
    margin-left: 15rem;
    padding-top: 4rem;
    position: relative; }
    @media only screen and (max-width: 37.5em) {
      .introduction__bottom-image {
        margin-left: 10rem;
        margin-top: -10rem;
        padding-top: 0;
        width: 85%;
        z-index: -1; } }

.flex-wrap {
  display: flex;
  justify-content: flex-end;
  position: relative; }

.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 5vw 10vh; }
  .notfound__title {
    font-size: 12vw;
    text-align: center;
    margin-bottom: 5rem;
    animation: grow 1.2s 1.3s;
    animation-fill-mode: backwards; }
    @media only screen and (max-width: 37.5em) {
      .notfound__title {
        font-size: 22vw; } }
  .notfound__text {
    font-size: 2.5vw;
    font-family: sans-serif;
    text-align: center;
    animation: comeFromLeft 1.1s 2.9s;
    animation-fill-mode: backwards;
    color: #797979; }
    @media only screen and (max-width: 37.5em) {
      .notfound__text {
        font-size: 5vw; } }
  .notfound__image {
    width: 15vw;
    position: absolute;
    right: 2rem; }
    @media only screen and (max-width: 37.5em) {
      .notfound__image {
        width: 35vw;
        position: static;
        right: auto; } }

.opening {
  background-image: url("/public/img/opening/opening.jpg");
  background-size: cover;
  height: 100vh;
  width: 100%;
  animation: fadeIn 2s;
  animation-fill-mode: backwards;
  display: grid;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 31.25em) {
    .opening {
      background-image: url("/public/img/opening/mobile-opening.jpg"); } }
  .opening__name-box {
    padding: 1rem 2rem;
    background-color: white;
    position: relative; }
    @media only screen and (max-width: 31.25em) {
      .opening__name-box {
        width: 100vw;
        text-align: center;
        top: 16%; } }
    .opening__name-box::before {
      content: "";
      display: block;
      height: .3rem;
      width: 30.5rem;
      background-color: transparent;
      z-index: 1;
      position: absolute;
      bottom: .5rem;
      left: 1.8rem; }
      @media only screen and (max-width: 37.5em) {
        .opening__name-box::before {
          display: none; } }
    .opening__name-box:hover::before {
      background-color: #9b9b9b; }

.series__top {
  padding: 2rem 0;
  position: sticky;
  background-color: gainsboro;
  top: 0; }

.series__back-btn {
  position: absolute;
  left: 4rem;
  top: 1rem; }
  @media only screen and (max-width: 37.5em) {
    .series__back-btn {
      left: 2.8rem; } }
  @media only screen and (max-width: 31.25em) {
    .series__back-btn {
      top: 1.5rem; } }
  @media only screen and (max-width: 400px) {
    .series__back-btn {
      top: 2.5rem; } }

.series__heading {
  text-align: center;
  margin-right: 3rem; }
  @media only screen and (max-width: 700px) {
    .series__heading {
      margin-right: 0; } }
  @media only screen and (max-width: 400px) {
    .series__heading {
      margin-left: 3rem;
      margin-top: 1rem; } }

.series__grid {
  padding: 1rem 12rem 10rem 18rem;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem; }
  @media only screen and (max-width: 75em) {
    .series__grid {
      padding: 1rem 8rem 10rem; } }
  @media only screen and (max-width: 1000px) {
    .series__grid {
      grid-template-columns: repeat(3, 1fr); } }
  @media only screen and (max-width: 700px) {
    .series__grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem; } }
  @media only screen and (max-width: 31.25em) {
    .series__grid {
      padding: 1rem 2rem 5rem; } }
  @media only screen and (max-width: 400px) {
    .series__grid {
      grid-template-columns: 1fr; } }

.series__item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem; }

.series__caption {
  text-align: left;
  font-family: sans-serif;
  font-size: 1.7rem;
  width: 9rem;
  cursor: pointer;
  transition: all .3s;
  color: transparent; }
  @media only screen and (max-width: 1000px) {
    .series__caption {
      color: #b4b4b4; } }
  @media only screen and (max-width: 700px) {
    .series__caption {
      font-size: 2.2rem;
      width: 12rem; } }

.series__icon {
  width: 10rem;
  height: 10rem;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  margin-right: 3.2rem;
  cursor: pointer;
  transition: all .2s;
  outline: 1.2rem solid #dddddd; }
  @media only screen and (max-width: 700px) {
    .series__icon {
      width: 17vw;
      height: 17vw; } }
  @media only screen and (max-width: 400px) {
    .series__icon {
      width: 30vw;
      height: 30vw;
      outline: 1.5rem solid #dbdada; } }
  .series__icon:hover {
    background-color: black;
    outline: 1.5rem solid black;
    transform: scale(1.1); }
  .series__icon:hover + figcaption {
    color: #a1a1a1; }
  .series__icon--1 {
    animation: circle1 .5s 1s;
    animation-fill-mode: backwards; }

@keyframes circle1 {
  0% {
    opacity: 0;
    transform: translate(250px, 70px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--2 {
    animation: circle2 .7s .4s;
    animation-fill-mode: backwards; }

@keyframes circle2 {
  0% {
    opacity: 0;
    transform: translate(75px, -10px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--3 {
    animation: circle3 .7s .7s;
    animation-fill-mode: backwards; }

@keyframes circle3 {
  0% {
    opacity: 0;
    transform: translate(-20px, 30px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--4 {
    animation: circle4 .7s .9s;
    animation-fill-mode: backwards; }

@keyframes circle4 {
  0% {
    opacity: 0;
    transform: translate(-250px, 90px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--5 {
    animation: circle5 .7s .7s;
    animation-fill-mode: backwards; }

@keyframes circle5 {
  0% {
    opacity: 0;
    transform: translate(500px, -20px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--6 {
    animation: circle6 .7s .6s;
    animation-fill-mode: backwards; }

@keyframes circle6 {
  0% {
    opacity: 0;
    transform: translate(50px, 60px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--7 {
    animation: circle7 .7s .7s;
    animation-fill-mode: backwards; }

@keyframes circle7 {
  0% {
    opacity: 0;
    transform: translate(150px, -30px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--8 {
    animation: circle8 .4s .8s;
    animation-fill-mode: backwards; }

@keyframes circle8 {
  0% {
    opacity: 0;
    transform: translate(-450px, 55px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--9 {
    animation: circle9 .5s .9s;
    animation-fill-mode: backwards; }

@keyframes circle9 {
  0% {
    opacity: 0;
    transform: translate(150px, -50px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--10 {
    animation: circle10 .7s .8s;
    animation-fill-mode: backwards; }

@keyframes circle10 {
  0% {
    opacity: 0;
    transform: translate(30px, -130px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--11 {
    animation: circle11 .7s .7s;
    animation-fill-mode: backwards; }

@keyframes circle11 {
  0% {
    opacity: 0;
    transform: translate(-10px, -100px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }
  .series__icon--12 {
    animation: circle12 .7s 1.3s;
    animation-fill-mode: backwards; }

@keyframes circle12 {
  0% {
    opacity: 0;
    transform: translate(-230px, -150px) scale(0.7); }
  100% {
    opacity: 1;
    transform: translate(1) scale(1); } }

.videos {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7vh; }
  .videos__title {
    margin-bottom: .5rem; }
  .videos__description {
    font-size: 1.2vw;
    color: darkgrey;
    letter-spacing: .1rem;
    margin-bottom: 2rem; }
    @media only screen and (max-width: 56.25em) {
      .videos__description {
        font-size: 1.8vw; } }
    @media only screen and (max-width: 37.5em) {
      .videos__description {
        font-size: 2.5vw; } }
  .videos__footer {
    height: 20vh;
    width: 100%;
    background-image: url("/public/img/videos/videos-footer.jpg");
    background-position: center;
    background-size: cover;
    margin-bottom: -3px;
    opacity: .5; }
    @media only screen and (max-width: 37.5em) {
      .videos__footer {
        display: none; } }
  .videos__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8vh; }
